import React from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { clearLocalStorageAccessToken, clearSessionStorageAccessToken } from "../utils/token";
import { baseApi } from "../api/base-api";
import { useAppDispatch } from "../app/reducers/hooks";
import { logoutAction } from "../app/reducers/profile-slice";
import Dashboard from "./Dashboard";
import Users from "./users/Users";
import Feeds from "./feeds/Feeds";
import Version from "./version/Version";
import Notice from "./notice/Notice";
import Report from "./report/Report";
import PointProduct from "./point/PointProduct";
import PointExchange from "./point/PointExchange";
import { Pathname } from "../model/Pathname";
import SidebarMenu from "../components/SidebarMenu";
import AdminRegister from "./admin/AdminRegister";
import DailyDataAnalysis from "./analytics/DailyDataAnalysis";
import Banners from "./banner/Banners";
import BannerSet from "./banner/BannerSet";
import Whitelist from "./whitelist/Whitelist";
import NotificationBatchPage from "./notification/NotificationBatchPage";
import BlockUsers from "./report/BlockUsers";
import PointLogAnalytics from "./point/PointLogAnalytics";

const Home = () => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    clearLocalStorageAccessToken();
    clearSessionStorageAccessToken();
    delete baseApi.defaults.headers.common["Authorization"];
    dispatch(logoutAction());
  }

  return (
    <Container>
      <Sidebar>
        <HeaderSection>
          <Title>부라보 관리자</Title>
          <LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>
        </HeaderSection>
        <Menu>
          <SidebarMenu />
        </Menu>
      </Sidebar>
      <Content>
        <Routes>
          <Route path="/" element={<Users />} />
          <Route path={Pathname.User} element={<Users />} />
          <Route path={Pathname.Feed} element={<Feeds />} />
          <Route path={Pathname.Banners} element={<Banners />} />
          <Route path={Pathname.BannerSet} element={<BannerSet />} />
          <Route path={Pathname.Report} element={<Report />} />
          <Route path={Pathname.PointProduct} element={<PointProduct />} />
          <Route path={Pathname.PointExchange} element={<PointExchange />} />
          <Route path={Pathname.Notice} element={<Notice />} />
          <Route path={Pathname.PointLogAnalytics} element={<PointLogAnalytics />} />
          <Route path={Pathname.DailyDataAnalysis} element={<DailyDataAnalysis />} />
          <Route path={Pathname.Version} element={<Version />} />
          <Route path={Pathname.BlockUsers} element={<BlockUsers />} />
          <Route path={Pathname.AdminRegister} element={<AdminRegister />} />
          <Route path={Pathname.Whitelist} element={<Whitelist />} />
          <Route path={Pathname.NotificationBatch} element={<NotificationBatchPage />} />
          <Route path={Pathname.Dashboard} element={<Dashboard />} />
        </Routes>
      </Content>
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  font-family: 'Pretendard', sans-serif;
  background-color: #f7f8fc;
  // position: relative;
  overflow: hidden;
`;

const Sidebar = styled.div`
  width: 240px;
  background: linear-gradient(180deg, #2f3e4e 0%, #1a2634 100%);
  padding: 24px;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.15);
  color: #fff;
  display: flex;
  flex-direction: column;
  // position: fixed;
  min-height: 100vh;
  z-index: 100;
  transition: all 0.3s ease;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
  padding: 12px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Title = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  letter-spacing: -0.5px;
`;

const LogoutButton = styled.button`
  background-color: #e74c3c;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.95em;
  font-weight: 500;

  &:hover {
    background-color: #c0392b;
  }

  &:active {
    transform: translateY(1px);
  }
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 3px;
  }
`;

const Content = styled.div`
  flex: 1;
  // margin-left: 240px;
  background-color: #f7f8fc;
  overflow-y: auto;
  min-height: 100vh;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.05);
  padding: 20px;
`;

export default Home;
