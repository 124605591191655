import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
    Typography,
    Box,
    Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface UpdateDayOfExerciseDialogProps {
    open: boolean;
    onClose: () => void;
    userId: string;
    currentDays: number;
    onUpdate: (userId: string, days: number) => Promise<void>;
}

const UpdateDayOfExerciseDialog: React.FC<UpdateDayOfExerciseDialogProps> = ({
    open,
    onClose,
    userId,
    currentDays,
    onUpdate
}) => {
    const [days, setDays] = useState(currentDays.toString());
    const [error, setError] = useState('');

    const handleSubmit = async () => {
        const newDays = parseInt(days);
        if (isNaN(newDays) || newDays < 0) {
            setError('올바른 숫자를 입력해주세요');
            return;
        }
        await onUpdate(userId, newDays);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)'
                }
            }}
        >
            <DialogTitle
                sx={{
                    m: 0,
                    p: 2.5,
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    borderBottom: '1px solid',
                    borderColor: 'divider'
                }}
            >
                운동일 수 수정
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 12,
                        top: 12,
                        color: 'grey.500',
                        '&:hover': {
                            backgroundColor: 'grey.100'
                        }
                    }}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 3 }}>
                <Alert
                    severity="info"
                    sx={{
                        mb: 3,
                        '& .MuiAlert-message': {
                            fontSize: '0.875rem'
                        },
                        marginTop: "10px"
                    }}
                >
                    운동일 수를 수정하면 최근 운동 시간이 현재 시간으로 변경됩니다.
                </Alert>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box sx={{
                        p: 2,
                        bgcolor: 'grey.50',
                        borderRadius: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Typography variant="body2" color="text.secondary">
                            현재 운동일 수
                        </Typography>
                        <Typography variant="body1" fontWeight={500}>
                            {currentDays}일
                        </Typography>
                    </Box>
                    <TextField
                        fullWidth
                        label="새로운 운동일 수"
                        type="number"
                        value={days}
                        onChange={(e) => {
                            setDays(e.target.value);
                            setError('');
                        }}
                        error={!!error}
                        helperText={error}
                        InputProps={{
                            inputProps: { min: 0 },
                            sx: { borderRadius: 1 }
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: 'primary.main',
                                },
                            },
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions
                sx={{
                    p: 2.5,
                    gap: 1,
                    borderTop: '1px solid',
                    borderColor: 'divider'
                }}
            >
                <Button
                    onClick={onClose}
                    color="inherit"
                    sx={{
                        fontWeight: 500,
                        px: 3
                    }}
                >
                    취소
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                        fontWeight: 500,
                        px: 3,
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 'none'
                        }
                    }}
                >
                    수정
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateDayOfExerciseDialog; 