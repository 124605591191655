import { AxiosInstance } from "axios";
import { BasePageDto, BaseResponse } from "./base-response";
import { baseApi } from "./base-api";
import { SignInAdminResponse } from "../types/user/response/SignInAdminResponse";
import { SignInPayload } from "../types/user/request/SignInPayload";
import { UserSearchParams } from "../types/user/request/UserSearchParams";
import { AdminUserResponse } from "../types/user/response/AdminUserResponse";
import { UpdateUserPointRequest } from "../types/user/request/UpdateUserPointRequest";
import { RegisterAdminRequest } from "../types/admin/RegisterAdminRequest";
import { RegisterSignUpWhitelistRequest } from "../types/user/request/RegisterSignUpWhitelistRequest";
import { UserMissionResponse, UserMissionResponseWithUser } from "../types/user/response/UserMissionResponse";

class UserApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async registerAdmin(request: RegisterAdminRequest): Promise<void> {
        await this._api.post("/api/admin/register", request);
    }

    async signIn(payload: SignInPayload): Promise<BaseResponse<SignInAdminResponse>> {
        return await this._api.post("/api/admin/sign-in", payload);
    }

    async getUsers(params: UserSearchParams): Promise<BasePageDto<AdminUserResponse>> {
        const response = await this._api.get<BasePageDto<AdminUserResponse>>("/api/admin/users", {
            params,
        });
        return response.data;
    }

    async getUserDetail(userId: string): Promise<BaseResponse<AdminUserResponse>> {
        return await this._api.get(`/api/admin/users/${userId}`);
    }

    async blockUser(userId: string): Promise<void> {
        await this._api.patch(`/api/admin/users/${userId}/block`);
    }

    async updateUserPoint(userId: string, payload: UpdateUserPointRequest): Promise<void> {
        await this._api.patch(`/api/admin/users/${userId}/point`, payload);
    }

    async registerSignUpWhitelist(request: RegisterSignUpWhitelistRequest): Promise<void> {
        await this._api.post("/api/admin/users/sign-up/whitelist", request);
    }

    async getUserMission(userId: string): Promise<BaseResponse<UserMissionResponse>> {
        return await this._api.get(`/api/admin/user-mission/${userId}`);
    }

    async getUserMissions(): Promise<BaseResponse<UserMissionResponseWithUser[]>> {
        return await this._api.get(`/api/admin/user-missions`);
    }

    async startUserMission(userId: string): Promise<void> {
        await this._api.post(`/api/admin/user-mission/${userId}`);
    }

    async resetSocialInfo(userId: string): Promise<void> {
        await this._api.patch(`/api/admin/users/${userId}/reset-social`);
    }

    async updateUserDayOfExercise(userId: string, dayOfExercise: number): Promise<void> {
        await this._api.patch(`/api/admin/users/${userId}/day-of-exercise`, { dayOfExercise });
    }
}

export const userApi = new UserApi(baseApi);
