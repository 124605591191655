import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { Pathname } from "../model/Pathname";

interface MenuConfig {
  to: string;
  label: string;
}

const MenuItem = styled.li`
  margin: 12px 0;
  position: relative;
`;

const StyledLink = styled(Link) <{ isActive: boolean }>`
  text-decoration: none;
  color: ${(props) => (props.isActive ? "#ffffff" : "#c0c7d1")};
  font-size: 1.05em;
  display: flex;
  align-items: center;
  padding: 14px 16px;
  border-radius: 12px;
  transition: all 0.3s ease;
  font-weight: ${(props) => (props.isActive ? "600" : "400")};
  letter-spacing: -0.3px;
  position: relative;
  overflow: hidden;

  background-color: ${(props) => (props.isActive ? "#5a6d85" : "transparent")};
  box-shadow: ${(props) => props.isActive ? "0 4px 12px rgba(0,0,0,0.15)" : "none"};

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(45deg, #64B5F6, #2196F3);
    opacity: ${(props) => (props.isActive ? "1" : "0")};
    transition: opacity 0.3s ease;
  }

  &:hover {
    background-color: #3c4b5e;
    color: #ffffff;
    
    &:before {
      opacity: 1;
    }
  }

  &:active {
    transform: scale(0.98) translateX(4px);
  }
`;

const SidebarMenu: React.FC = () => {
  const location = useLocation();

  const handleMenuClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const createMenuItems = (menus: MenuConfig[]) => {
    return menus.map(({ to, label }) => {
      const isActive = location.pathname.startsWith(to + "/") || location.pathname === to;

      return (
        <MenuItem key={to}>
          <StyledLink to={to} isActive={isActive}>
            {label}
          </StyledLink>
        </MenuItem>
      );
    });
  };

  const menuConfig = [
    { to: Pathname.Feed, label: "📋 게시글 관리" },
    { to: Pathname.Banners, label: "🌠 배너 관리" },
    { to: Pathname.BannerSet, label: "📚 배너 세트 관리" },
    { to: Pathname.Report, label: "⚠️ 신고 관리" },
    { to: Pathname.BlockUsers, label: "🚫 유저 차단 목록" },
    { to: Pathname.PointProduct, label: "🎁 포인트 상품 관리" },
    { to: Pathname.PointExchange, label: "📦 상품 교환 관리" },
    { to: Pathname.PointLogAnalytics, label: "📈️ 포인트 로그 분석" },
    { to: Pathname.Notice, label: "📢 공지사항 관리" },
    { to: Pathname.NotificationBatch, label: "🔔 정기 알림 관리" },
    { to: Pathname.DailyDataAnalysis, label: "📈️ 일일 데이터" },
    { to: Pathname.Whitelist, label: "🔐 화이트리스트 추가" },
    { to: Pathname.Version, label: "⚙️ 버전 관리" },
  ];

  return <>
    <MenuItem>
      <StyledLink
        to={Pathname.User}
        isActive={location.pathname.startsWith(Pathname.User + "/") || location.pathname === Pathname.User || location.pathname === "/"}
        onClick={handleMenuClick}
      >
        👥 유저 관리
      </StyledLink>
    </MenuItem>
    {createMenuItems(menuConfig)}
  </>;
};

export default SidebarMenu;
