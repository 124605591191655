import React from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Typography, IconButton } from '@mui/material';
import { UserMissionResponse } from '../../types/user/response/UserMissionResponse';

interface UserMissionDialogProps {
    open: boolean;
    onClose: () => void;
    mission: UserMissionResponse | null;
}

const UserMissionDialog: React.FC<UserMissionDialogProps> = ({ open, onClose, mission }) => {
    if (!mission) return null;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                미션 상세 정보
                <IconButton onClick={onClose}>✕</IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'grid', gap: 2 }}>
                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">미션 상태</Typography>
                        <Typography>
                            {mission.missionStatus === 'PENDING' ? '인증 글 작성 중' :
                                mission.missionStatus === 'IN_PROGRESS' ? '친구 초대 진행 중' :
                                    mission.missionStatus === 'SUCCESS' ? '성공' : '-'}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">미지급 포인트</Typography>
                        <Typography>{mission.missionPoint}P</Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">인증 글 작성 횟수</Typography>
                        <Typography>{mission.missionStartCount}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">친구 초대 횟수</Typography>
                        <Typography>{mission.missionCount}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">인증 5회 완료일시</Typography>
                        <Typography>{mission.inProgressAt || '-'}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" color="text.secondary">미션 지정일시</Typography>
                        <Typography>{mission.createdAt}</Typography>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default UserMissionDialog;
