import { AxiosInstance } from "axios";
import { BasePageDto, BaseResponse } from "./base-response";
import { baseApi } from "./base-api";
import { ReportResponse, ReportSearchParams } from "../types/report/response/ReportResponse";
import { BlockUserListResponse } from "../types/report/response/BlockUserResponse";

class ReportApi {
    private _api: AxiosInstance;

    constructor(api: AxiosInstance) {
        this._api = api;
    }

    async getPagedReports(params: ReportSearchParams): Promise<BasePageDto<ReportResponse>> {
        const response = await this._api.get<BasePageDto<ReportResponse>>("/api/admin/reports", { params });
        return response.data;
    }

    async confirmReport(reportId: string): Promise<void> {
        await this._api.post(`/api/admin/reports/${reportId}/confirm`);
    }

    async getBlockUsers(): Promise<BaseResponse<BlockUserListResponse>> {
        return await this._api.get("/api/admin/users/block-users");
    }

    async unblockUser(blockId: string): Promise<void> {
        await this._api.delete(`/api/admin/users/block-users/${blockId}`);
    }
}

export const reportApi = new ReportApi(baseApi);
