import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { reportApi } from "../../api/report-api";
import { BlockUserResponse } from "../../types/report/response/BlockUserResponse";
import { StyledTable, StyledTableCell, StyledTableContainer, StyledTableHead } from "../../components/Table";
import { ProfileImage, UserInfo } from "../../components/UserInfo";
import PageHeader from "../../components/PageHeader";
import { TableBody, TableRow } from "@mui/material";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { toast } from "react-toastify";

const BlockUsers: React.FC = () => {
    const [blockUsers, setBlockUsers] = useState<BlockUserResponse[]>([]);

    const fetchBlockUsers = async () => {
        try {
            const response = await reportApi.getBlockUsers();
            setBlockUsers(response.data.blockUsers);
        } catch (error) {
            console.error("Failed to fetch block users:", error);
        }
    };

    const handleUnblock = async (blockId: string) => {
        const isConfirmed = window.confirm("정말로 차단을 해제하시겠습니까?");
        if (!isConfirmed) return;

        try {
            await reportApi.unblockUser(blockId);
            toast.success("차단이 해제되었습니다.");
            fetchBlockUsers();
        } catch (error) {
            console.error("Failed to unblock user:", error);
        }
    };

    useEffect(() => {
        fetchBlockUsers();
    }, []);

    return (
        <Box p={4}>
            <PageHeader title="차단 목록" subtitle="사용자 간 차단 현황을 관리합니다." />

            <StyledTableContainer>
                <StyledTable>
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell nowrap={true}>차단한 사용자</StyledTableCell>
                            <StyledTableCell nowrap={true}>차단된 사용자</StyledTableCell>
                            <StyledTableCell align="right" nowrap={true}>관리</StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {blockUsers.map((blockUser, index) => (
                            <TableRow key={index}>
                                <StyledTableCell>
                                    <UserInfo>
                                        <ProfileImage
                                            src={blockUser.applyUser.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`}
                                            alt="프로필 이미지"
                                        />
                                        <Typography variant="body2">{blockUser.applyUser.nickname}</Typography>
                                    </UserInfo>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <UserInfo>
                                        <ProfileImage
                                            src={blockUser.targetUser.profileImageUrl || `${process.env.PUBLIC_URL}/assets/default_profile.png`}
                                            alt="프로필 이미지"
                                        />
                                        <Typography variant="body2">{blockUser.targetUser.nickname}</Typography>
                                    </UserInfo>
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<LockOpenIcon sx={{ fontSize: 16 }} />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleUnblock(blockUser.blockId);
                                        }}
                                    >
                                        해제
                                    </Button>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>
        </Box>
    );
};

export default BlockUsers; 