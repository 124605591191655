import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Chip,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    IconButton
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import styled from "styled-components";
import { userApi } from "../../api/user-api";
import { AdminUserResponse } from "../../types/user/response/AdminUserResponse";
import { UserStatus } from "../../types/user/enum/UserStatus";
import { BasePageDto } from "../../api/base-response";
import { toast } from "react-toastify";
import UpdatePointDialog from "./UpdatePointDialog";
import { ProfileImage, UserInfo } from "../../components/UserInfo";
import PageHeader from "../../components/PageHeader";
import SearchIcon from '@mui/icons-material/Search';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PointDisplay from "../../components/PointDisplay";
import { SmallTextTableCell, StyledTableCell } from "../../components/Table";
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { UserMissionResponse } from "../../types/user/response/UserMissionResponse";
import UserMissionDialog from "./UserMissionDialog";
import { generateAllMissionExcel } from "../../utils/AllMissionExcelGenerator";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UpdateDayOfExerciseDialog from './UpdateDayOfExerciseDialog';

// 스타일 정의
const StyledTableContainer = styled(TableContainer)`
  margin: 20px 0;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

const StyledTable = styled(Table)`
  //min-width: 800px;
  width: 100%;
`;

const StyledTableHead = styled(TableHead)`
  background-color: #f5f5f5;
`;

const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: #f9f9f9;
  }
`;


// 컴포넌트
const Users: React.FC = () => {
    const [page, setPage] = useState(1); // 현재 페이지
    const [totalPages, setTotalPages] = useState(1); // 총 페이지 수
    const [users, setUsers] = useState<AdminUserResponse[]>([]); // 유저 목록
    const [loading, setLoading] = useState(false); // 로딩 상태
    const [keyword, setKeyword] = useState(""); // 닉네임 검색
    const [status, setStatus] = useState<UserStatus | "">(""); // 상태 검색
    const [selectedUser, setSelectedUser] = useState<{ id: string; points: number } | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [missionDialogOpen, setMissionDialogOpen] = useState(false);
    const [selectedMission, setSelectedMission] = useState<UserMissionResponse | null>(null);
    const [dayOfExerciseDialog, setDayOfExerciseDialog] = useState<{
        open: boolean;
        userId: string;
        currentDays: number;
    }>({
        open: false,
        userId: '',
        currentDays: 0
    });

    const handleOpenDialog = (userId: string, currentPoints: number) => {
        setSelectedUser({ id: userId, points: currentPoints });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setSelectedUser(null);
        setOpenDialog(false);
    };

    const handlePointsUpdated = () => {
        fetchUsers()
    };

    // 유저 데이터 로드 함수
    const fetchUsers = async () => {
        setLoading(true);
        try {
            const requestStatus = status === "" ? null : status; // 빈 값은 null로 처리
            const response: BasePageDto<AdminUserResponse> = await userApi.getUsers({ page: page - 1, size: 10, keyword: keyword, status: requestStatus });
            setUsers(response.contents);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error("유저 목록을 가져오는 중 에러 발생:", error);
        } finally {
            setLoading(false);
        }
    };

    // 페이지 변경 시 데이터 로드
    useEffect(() => {
        fetchUsers();
    }, [page]);

    const handleDisableUser = async (userId: string) => {
        const confirmDisable = window.confirm(
            "비활성화를 하면 모든 게시글과 댓글 또한 비활성화 됩니다.\n복구하려면 개발자의 힘이 많이 듭니다.\n정말 비활성화하시겠습니까?"
        );

        if (confirmDisable) {
            try {
                await userApi.blockUser(userId);
                toast.success("유저가 성공적으로 비활성화되었습니다.");
                fetchUsers(); // 유저 목록을 다시 불러옵니다.
            } catch (error) {
            }
        }
    };

    const handleCreateMission = async (userId: string) => {
        try {
            await userApi.startUserMission(userId);
            toast.success("미션 생성 완료");
        } catch (error) {
        }
    };

    const handleViewMission = async (userId: string) => {
        try {
            const response = await userApi.getUserMission(userId);
            if (response.data) {
                setSelectedMission(response.data);
                setMissionDialogOpen(true);
            } else {
                toast.error("미션이 없습니다.");
            }
        } catch (error) {
        }
    };

    const handleViewAllMissions = async () => {
        try {
            const response = await userApi.getUserMissions();
            generateAllMissionExcel(response.data);
        } catch (error) {
        }
    };

    const handleSearch = () => {
        setPage(1);
        fetchUsers();
    };

    const handleResetSocialInfo = async (userId: string) => {
        const confirmReset = window.confirm(
            "소셜 정보를 초기화하시겠습니까?\n이 작업은 되돌릴 수 없습니다."
        );

        if (confirmReset) {
            try {
                await userApi.resetSocialInfo(userId);
                toast.success("소셜 정보가 초기화되었습니다.");
                fetchUsers();
            } catch (error) {
            }
        }
    };

    const handleUpdateDayOfExercise = async (userId: string, days: number) => {
        try {
            await userApi.updateUserDayOfExercise(userId, days);
            toast.success('운동일 수가 수정되었습니다.');
            fetchUsers();
        } catch (error) {
            toast.error('운동일 수 수정에 실패했습니다.');
        }
    };

    return (
        <Box p={4}>
            <PageHeader title="유저 관리" />

            {/* 검색 필드 */}
            <Box mb={3} display="flex" alignItems="center" gap={2}>
                <TextField
                    label="닉네임 / 이름 / 전화번호 통합 검색"
                    variant="outlined"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    sx={{ width: "300px" }}
                />
                <TextField
                    label="상태"
                    variant="outlined"
                    select
                    value={status}
                    onChange={(e) => setStatus(e.target.value as UserStatus | "")}
                    sx={{ width: "200px" }}
                >
                    <MenuItem value="">전체</MenuItem>
                    <MenuItem value={UserStatus.ACTIVE}>활성</MenuItem>
                    <MenuItem value={UserStatus.BLOCKED}>비활성</MenuItem>
                    <MenuItem value={UserStatus.DELETED}>탈퇴</MenuItem>
                </TextField>
                <Button variant="contained" color="primary" onClick={handleSearch} startIcon={<SearchIcon />}>
                    검색
                </Button>
                <Button
                    variant="contained"
                    color="info"
                    onClick={handleViewAllMissions}
                    startIcon={<SearchIcon />}
                    sx={{ backgroundColor: '#90a4ae' }}
                >
                    미션 대상자 전체 엑셀 조회
                </Button>
            </Box>

            <StyledTableContainer as="div">
                <StyledTable>
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell nowrap={true}>닉네임</StyledTableCell>
                            <StyledTableCell nowrap={true}>목표</StyledTableCell>
                            <StyledTableCell nowrap={true}>운동 #</StyledTableCell>
                            <StyledTableCell nowrap={true}>최근 운동일시</StyledTableCell>
                            <StyledTableCell nowrap={true}>포인트</StyledTableCell>
                            <StyledTableCell nowrap={true}>부라보</StyledTableCell>
                            <StyledTableCell nowrap={true}>팬</StyledTableCell>
                            <StyledTableCell nowrap={true}>관심</StyledTableCell>
                            <StyledTableCell nowrap={true}>이름</StyledTableCell>
                            <StyledTableCell nowrap={true}>성별</StyledTableCell>
                            <StyledTableCell nowrap={true}>생년월일</StyledTableCell>
                            <StyledTableCell nowrap={true}>전화번호</StyledTableCell>
                            <StyledTableCell nowrap={true}>상태</StyledTableCell>
                            <StyledTableCell nowrap={true}>비활성화</StyledTableCell>
                            <StyledTableCell nowrap={true}>포인트 수정</StyledTableCell>
                            <StyledTableCell nowrap={true}>최근 방문일시</StyledTableCell>
                            <StyledTableCell nowrap={true}>계정 생성일시</StyledTableCell>
                            <StyledTableCell nowrap={true}>미션 생성</StyledTableCell>
                            <StyledTableCell nowrap={true}>미션 조회</StyledTableCell>
                            <StyledTableCell nowrap={true}>소셜 초기화</StyledTableCell>
                            <StyledTableCell nowrap={true}>유저 ID</StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={13} align="center">
                                    데이터 로딩 중...
                                </TableCell>
                            </TableRow>
                        ) : (
                            users.map((user) => (
                                <StyledTableRow key={user.userId}>
                                    <StyledTableCell nowrap={true}>
                                        <UserInfo>
                                            <ProfileImage
                                                src={
                                                    user.profileImageUrl
                                                        ? user.profileImageUrl
                                                        : `${process.env.PUBLIC_URL}/assets/default_profile.png`
                                                }
                                                alt="프로필"
                                            />
                                            {user.nickname}
                                        </UserInfo>
                                    </StyledTableCell>
                                    <StyledTableCell nowrap={true}>{user.goal || "-"}</StyledTableCell>
                                    <StyledTableCell nowrap={true}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {user.dayOfExercise} 일
                                            <IconButton
                                                size="small"
                                                onClick={() => setDayOfExerciseDialog({
                                                    open: true,
                                                    userId: user.userId,
                                                    currentDays: user.dayOfExercise
                                                })}
                                                sx={{
                                                    ml: 1,
                                                    backgroundColor: 'primary.main',
                                                    color: 'white',
                                                    '&:hover': {
                                                        backgroundColor: 'primary.dark',
                                                    },
                                                    width: 20,
                                                    height: 20
                                                }}
                                            >
                                                <EditIcon sx={{ fontSize: 14 }} />
                                            </IconButton>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell>{user.exercisedAt || "-"}</StyledTableCell>
                                    <StyledTableCell>
                                        <PointDisplay points={user.point} />
                                    </StyledTableCell>
                                    <StyledTableCell>{user.feedLikeCount + user.commentLikeCount}</StyledTableCell>
                                    <StyledTableCell>{user.followerCount}</StyledTableCell>
                                    <StyledTableCell>{user.followingCount}</StyledTableCell>
                                    <StyledTableCell nowrap={true}>{user.name || "-"}</StyledTableCell>
                                    <StyledTableCell>
                                        {user.gender === 'male' ? '남' : user.gender === 'female' ? '여' : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell nowrap={true}>
                                        {user.birthyear && user.birthdate
                                            ? `${user.birthyear}-${user.birthdate.slice(0, 2)}-${user.birthdate.slice(2, 4)}`
                                            : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell nowrap={true}>{user.phoneNumber || "-"}</StyledTableCell>
                                    <StyledTableCell>
                                        <Chip
                                            icon={
                                                user.status === UserStatus.ACTIVE
                                                    ? <CheckCircleIcon sx={{ color: 'inherit', fontSize: 16 }} />
                                                    : user.status === UserStatus.BLOCKED
                                                        ? <BlockIcon sx={{ color: 'inherit', fontSize: 16 }} />
                                                        : <CancelIcon sx={{ color: 'inherit', fontSize: 16 }} />
                                            }
                                            label={
                                                user.status === UserStatus.ACTIVE
                                                    ? "활성"
                                                    : user.status === UserStatus.BLOCKED
                                                        ? "비활성"
                                                        : "탈퇴"
                                            }
                                            style={{
                                                backgroundColor:
                                                    user.status === UserStatus.ACTIVE
                                                        ? "#4caf50"
                                                        : user.status === UserStatus.BLOCKED
                                                            ? "#f57c00"
                                                            : "#d26565",
                                                color: "#fff",
                                                fontWeight: "bold",
                                                padding: "2px 8px",
                                                borderRadius: "12px",
                                                height: "24px",
                                                fontSize: "13px",
                                                boxShadow: "none"
                                            }}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {user.status === UserStatus.ACTIVE && (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={() => handleDisableUser(user.userId)}
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    padding: '4px 8px',
                                                    minWidth: 'auto',
                                                    fontSize: '12px'
                                                }}
                                                size="small"
                                                startIcon={<BlockIcon sx={{ fontSize: 16 }} />}
                                            >
                                                비활성화
                                            </Button>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpenDialog(user.userId, user.point)}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                padding: '4px 8px',
                                                minWidth: 'auto',
                                                fontSize: '12px'
                                            }}
                                            size="small"
                                            startIcon={<EditIcon sx={{ fontSize: 16 }} />}
                                        >
                                            포인트 관리
                                        </Button>
                                    </StyledTableCell>
                                    <StyledTableCell>{user.recentVisitedAt || "-"}</StyledTableCell>
                                    <StyledTableCell>{user.createdAt}</StyledTableCell>
                                    <StyledTableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleCreateMission(user.userId)}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                padding: '4px 8px',
                                                minWidth: 'auto',
                                                fontSize: '12px',
                                                marginRight: '8px'
                                            }}
                                            size="small"
                                            startIcon={<AddIcon sx={{ fontSize: 16 }} />}
                                        >
                                            미션 생성
                                        </Button>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleViewMission(user.userId)}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                padding: '4px 8px',
                                                minWidth: 'auto',
                                                fontSize: '12px'
                                            }}
                                            size="small"
                                            startIcon={<VisibilityIcon sx={{ fontSize: 16 }} />}
                                        >
                                            미션 조회
                                        </Button>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Button
                                            variant="outlined"
                                            color="warning"
                                            onClick={() => handleResetSocialInfo(user.userId)}
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                padding: '4px 8px',
                                                minWidth: 'auto',
                                                fontSize: '12px'
                                            }}
                                            size="small"
                                            startIcon={<RestartAltIcon sx={{ fontSize: 16 }} />}
                                        >
                                            소셜 초기화
                                        </Button>
                                    </StyledTableCell>
                                    <SmallTextTableCell>{user.userId}</SmallTextTableCell>
                                </StyledTableRow>
                            ))
                        )}
                    </TableBody>
                </StyledTable>
            </StyledTableContainer>

            {selectedUser && (
                <UpdatePointDialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    userId={selectedUser.id}
                    currentPoints={selectedUser.points}
                    onPointsUpdated={handlePointsUpdated}
                />
            )}

            <Pagination
                count={totalPages}
                page={page}
                onChange={(e, value) => setPage(value)}
                color="primary"
                sx={{ mt: 3 }}
            />

            <UserMissionDialog
                open={missionDialogOpen}
                onClose={() => {
                    setMissionDialogOpen(false);
                    setSelectedMission(null);
                }}
                mission={selectedMission}
            />

            <UpdateDayOfExerciseDialog
                open={dayOfExerciseDialog.open}
                onClose={() => setDayOfExerciseDialog({ open: false, userId: '', currentDays: 0 })}
                userId={dayOfExerciseDialog.userId}
                currentDays={dayOfExerciseDialog.currentDays}
                onUpdate={handleUpdateDayOfExercise}
            />
        </Box>
    );
};

export default Users;
