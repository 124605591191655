import * as XLSX from 'xlsx';
import { UserMissionResponseWithUser } from '../types/user/response/UserMissionResponse';

export const generateAllMissionExcel = (missions: UserMissionResponseWithUser[]) => {
    const formattedData = missions.map(mission => ({
        '닉네임': mission.user.nickname,
        '미션 상태': mission.userMission.missionStatus === 'PENDING' ? '인증 글 작성 중' :
            mission.userMission.missionStatus === 'IN_PROGRESS' ? '친구 초대 진행 중' :
                mission.userMission.missionStatus === 'SUCCESS' ? '성공' : '-',
        '미지급 포인트': `${mission.userMission.missionPoint}P`,
        '인증 글 작성 횟수': mission.userMission.missionStartCount,
        '친구 초대 횟수': mission.userMission.missionCount,
        '인증 5회 완료일시': mission.userMission.inProgressAt || '-',
        '미션 지정일시': mission.userMission.createdAt
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const today = new Date().toISOString().split('T')[0];
    XLSX.writeFile(workbook, `미션_현황_${today}.xlsx`);
};
