import React, { useState } from "react";
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { pointApi } from "../../api/point-api";
import { PointLogAnalyticsResponse } from "../../types/point/response/PointLogAnalyticsResponse";
import PageHeader from "../../components/PageHeader";
import moment from "moment";
import SearchIcon from '@mui/icons-material/Search';

const PointLogAnalytics: React.FC = () => {
    const [analytics, setAnalytics] = useState<PointLogAnalyticsResponse | null>(null);
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [searchedStartDate, setSearchedStartDate] = useState<string | null>(null);
    const [searchedEndDate, setSearchedEndDate] = useState<string | null>(null);

    const fetchAnalytics = async () => {
        try {
            const response = await pointApi.getPointLogAnalytics({
                startDate,
                endDate,
            });
            setAnalytics(response.data);
            setSearchedStartDate(startDate);
            setSearchedEndDate(endDate);
        } catch (error) {
            console.error("Failed to fetch point log analytics:", error);
        }
    };

    const StatCard = ({ title, value, color, subtitle }: { title: string; value: number; color: string; subtitle?: string }) => (
        <Card
            sx={{
                height: '100%',
                borderRadius: '16px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)',
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)'
                }
            }}
        >
            <CardContent sx={{ p: 3 }}>
                <Typography
                    variant="subtitle1"
                    sx={{
                        color: 'text.secondary',
                        fontSize: '0.875rem',
                        mb: 0.5
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="h4"
                    sx={{
                        color,
                        fontWeight: 700,
                        fontSize: '2rem',
                        mb: subtitle ? 1 : 0
                    }}
                >
                    {value.toLocaleString()}P
                </Typography>
                {subtitle && (
                    <Typography
                        variant="body2"
                        sx={{
                            color: 'text.secondary',
                            fontSize: '0.75rem'
                        }}
                    >
                        {subtitle}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );

    return (
        <Box p={4}>
            <PageHeader title="포인트 로그 분석" subtitle="기간별 포인트 증감 현황을 확인합니다." />

            <Card
                sx={{
                    mb: 4,
                    borderRadius: '16px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)'
                }}
            >
                <CardContent sx={{ p: 3 }}>
                    <Box
                        display="flex"
                        gap={2}
                        alignItems="center"
                    >
                        <TextField
                            type="date"
                            label="시작일"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px'
                                }
                            }}
                        />
                        <TextField
                            type="date"
                            label="종료일"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '12px'
                                }
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={fetchAnalytics}
                            startIcon={<SearchIcon />}
                            sx={{
                                borderRadius: '12px',
                                height: '56px',
                                px: 4,
                                backgroundColor: '#333D4B',
                                '&:hover': {
                                    backgroundColor: '#1A1F27'
                                }
                            }}
                        >
                            검색
                        </Button>
                    </Box>
                </CardContent>
            </Card>

            {analytics && (
                <>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            mb: 3,
                            color: 'text.secondary',
                            fontSize: '0.875rem'
                        }}
                    >
                        {`${moment(searchedStartDate).format('YYYY.MM.DD')} ~ ${moment(searchedEndDate).format('YYYY.MM.DD')} 로 검색된 결과입니다.`}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <StatCard
                                title="실제 적립된 포인트"
                                value={analytics.sumNonProductPurchaseIncrease - analytics.sumNonProductPurchaseDecrease}
                                color="#2B62E4"
                                subtitle="포인트 적립에서 일반 포인트 차감을 제외한 실제 적립 포인트"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <StatCard
                                title="포인트 적립"
                                value={analytics.sumNonProductPurchaseIncrease}
                                color="#3182F6"
                                subtitle="운동 인증, 댓글, 관리자에 의해 적립된 포인트"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <StatCard
                                title="일반 포인트 차감"
                                value={analytics.sumNonProductPurchaseDecrease}
                                color="#4E5968"
                                subtitle="운동 인증글 삭제, 댓글 삭제, 관리자에 의해 차감된 포인트"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <StatCard
                                title="상품 구매 차감"
                                value={analytics.sumProductPurchaseDecrease}
                                color="#191F28"
                                subtitle="상품 구매로 사용된 포인트"
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default PointLogAnalytics; 